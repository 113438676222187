:root {
    --theme-card-chip-color-istanbul: #D9E1E7;
    --theme-card-chip-color-pune: #E9E7F0;
    --theme-card-chip-color-berlin: #EEE9EA;
    --theme-card-chip-color-nairobi: #EEE9EA;
    --them-card-chip-color-mimai: #E9ECE6;
    --theme-card-chip-color-paris: #EAE8EF;
    --theme-card-chip-color-tokyo: #E8EBE6;
    --theme-card-chip-color-mumbai: #E5E9EE;
}

.width-fit-important {
    width: fit-content !important;
}

.section-height {
    min-height: 64px;
}

.sticky-button {
    position: sticky;
    background: var(--color-white);
    padding-top: 12px;
    z-index: 999;
    top: 0;
}

.description {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
}
