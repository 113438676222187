@import "quill/dist/quill.core.css";
@import "quill/dist/quill.bubble.css";
@import "quill/dist/quill.snow.css";

.ql-toolbar.ql-snow {
    border: .5px solid #e5e7eb !important;
    box-shadow: 0 0 0 .5px #e5e7eb !important;
    border-radius: 8px 8px 0 0 !important;
    background-color: var(--color-white);
}

.ql-container.ql-snow {
    border-radius: 0 0 8px 8px !important;
}

.ql-editor {
    border-radius: 0 0 8px 8px !important;
    font-size: 0.875rem;
    padding: 8px 12px;
    font-family: 'Poppins', sans-serif;
    line-height: 26px;
    letter-spacing: 0;
}

.ql-editor ol li[data-list="bullet"] {
    margin-left: 4px;
}

.ql-editor ol li[data-list="ordered"] {
    margin-left: 12px;
}

.ql-editor {
    border: .5px solid #e5e7eb !important;
    box-shadow: 0 0 0 1px #e5e7eb !important;
}

.ql-editor ul, .ql-editor ol {
    padding-left: 0 !important;
}

.ql-editor li:not(.ql-direction-rtl)::before {
    padding-left: 10px !important;
    padding-right: 6px !important;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0 !important;
}

.ql-editor:focus {
    border: .5px solid var(--color-primary) !important;
    box-shadow: 0 0 0 1px var(--color-primary) !important;
}

.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:hover {
    .ql-stroke {
        fill: none;
        stroke: var(--color-primary);
    }

    .ql-fill {
        stroke: var(--color-primary);
    }
}

#content-viewer .ql-editor {
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.ql-editor.ql-blank::before {
    color: var(--color-extra-light-grey) !important;
}

@media screen and (max-width: 640px) {
    .ql-editor {
        font-size: 14px;
        line-height: 140%;
    }
}
