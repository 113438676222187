.primary-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    font-family: Poppins, sans-serif;
}

.secondary-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    font-family: Poppins, sans-serif;
}

.text-grey {
    color: var(--color-tertiary-light);
}

.text-heading-primary {
    color: var(--color-grey-dark);
}

.text-heading-secondary {
    color: var(--color-grey-light-dark);
}

.text-white {
    color: var(--color-white);
}

.text-primary {
    color: var(--color-primary) !important;
}

.text-heading {
    color: var(--color-tertiary) !important;
}

.text-heading-light {
    color: var(--color-black-extra-light);
}

.text-success-dark-light {
    color: var(--color-success-dark-light);
}

.text-green-dark-light {
    color: var(--color-green-dark-light);
}

.text-purple {
    color: var(--color-purple);
}

.text-purple-dark {
    color: var(--color-purple-medium-dark);
}

.text-tertiary {
    color: var(--color-tertiary);
}

.text-primary-light {
    color: var(--color-primary-light) !important;
}

.text-secondary {
    color: var(--color-secondary) !important;
}

.text-secondary-light {
    color: var(--color-secondary-light) !important;
}

.text-danger {
    color: var(--color-danger) !important;
}

.text-danger-light {
    color: var(--color-danger-light) !important;
}

.text-light-orange {
    color: var(--color-light-orange) !important;
}

.text-warning {
    color: var(--color-warning) !important;
}

.text-brown-dark-light {
    color: var(--color-brown-dark-light) !important;
}

.text-primary {
    color: var(--color-primary) !important;
}

.text-secondary {
    color: var(--color-secondary) !important;
}

.text-success {
    color: var(--color-success) !important;
}

.text-danger {
    color: var(--color-danger) !important;
}

.text-black {
    color: var(--color-black) !important;
}

.text-white {
    color: var(--color-white) !important;
}
