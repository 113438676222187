.bg-primary {
    background-color: var(--color-primary) !important;
}

.bg-primary-light {
    background-color: var(--color-primary-light) !important;
}

.bg-secondary {
    background-color: var(--color-secondary) !important;
}

.bg-secondary-light {
    background-color: var(--color-secondary-light) !important;
}

.bg-success {
    background-color: var(--color-success) !important;
}

.bg-success-light {
    background-color: var(--color-success-light) !important;
}

.bg-warning {
    background-color: var(--color-warning) !important;
}

.bg-warning-light {
    background-color: var(--color-warning-light) !important;
}

.bg-danger {
    background-color: var(--color-danger) !important;
}

.bg-danger-light {
    background-color: var(--color-danger-light) !important;
}

.bg-black {
    background-color: var(--color-black) !important;
}

.bg-white-light {
    background-color: var(--color-white-light) !important;
}

.bg-white {
    background: var(--color-white);
}

.bg-purple-dark {
    background: var(--color-purple-medium-dark);
}

.bg-green-light {
    background: var(--color-green-extra-light);
}

.bg-green-extra-light {
    background: var(--color-green-extra-dark-light-white);
}

.bg-yellow-light {
    background: var(--color-light-yellow-extra-light);
}

.bg-purple-light {
    background: var(--color-purple-light);
}

.bg-gradient {
    background: var(--dark-and-light-blue-gradient) !important;
}

.bg-gray {
    background: var(--color-stroke);
}
