.view-component-outer-container .overlay {
    text-align: center;
}

.view-component-outer-container {
    width: 100%;
    position: relative;

    .overlay {
        position: absolute;
        opacity: 0;
    }

    &:hover {
        .overlay {
            opacity: 1;
        }

        .view-component-inner-container {
            -webkit-filter: blur(3px);
            filter: blur(3px);
        }
    }
}
