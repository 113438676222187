.border-primary {
    border: 1px solid var(--color-primary) !important;
}

.border-primary-light {
    border: 1px solid var(--color-primary-light) !important;
}

.border-secondary {
    border: 1px solid var(--color-secondary) !important;
}

.border-secondary-light {
    border: 1px solid var(--color-secondary-light) !important;
}

.border-danger {
    border: 1px solid var(--color-danger) !important;
}

.border-stroke {
    border: 1px solid var(--color-stroke);
}

.border-bottom-stroke {
    border-bottom: 1px solid var(--color-stroke);
}

.border-top-stroke {
    border-top: 1px solid var(--color-stroke);
}

.border-stroke-dark {
    border: 1px solid var(--color-stroke-shadow);
}

.border-danger-light {
    border: 1px solid var(--color-danger-light) !important;
}

//snack-bar
.welcome-snack-bar {
    border: 2px solid var(--color-primary);
}

.warning-snack-bar {
    border: 2px solid var(--color-warning);
}

.info-snack-bar {
    border: 2px solid var(--color-primary);
}

.success-snack-bar {
    border: 2px solid var(--color-success-dark-light);
}

.danger-snack-bar {
    border: 2px solid var(--color-danger);
}

//border-color
.border-white {
    border-color: var(--color-white) !important;
}

.border-black {
    border-color: var(--color-black) !important;
}

.border-danger {
    border-color: var(--color-danger) !important;
}

.border-warning {
    border-color: var(--color-warning) !important;
}

.border-success {
    border-color: var(--color-success) !important;
}

.border-primary {
    border-color: var(--color-primary) !important;
}
