@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300&display=swap');
@import "app/styles/mobile";
@import "app/styles/tailwind";
@import "app/styles/override";
@import "app/styles/quill-rich-text-editor.scss";
//@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
//@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '@angular/cdk/overlay-prebuilt.css';
@import "app/styles/border";
@import "app/styles/text";
@import "app/styles/background-color";
@import "app/styles/resume";
@import "app/styles/common/content-hover";

:root {

    --color-primary: #1E7BFA;
    --color-primary-light: #EEF5FF;
    --color-light-primary: #d9e6f9;
    --color-primary-dark: #0058D1;
    --color-primary-extra-light: #54A0FF;
    --color-primary-extra-blue: #368EFC;
    --color-primary-contrast: #fff;
    --color-primary-extra-dark: #3562FB;
    --color-primary-extra-light-primary: #2c6bfc;
    --color-primary-very-light-primary: #2d6dfe;
    --color-border-gray: #D6E8FF;
    --color-white-gray: #DDD;

    --color-pink: #CDEBFC;
    --color-pink-white-light: #EAEEFF;
    --color-pink-light: #EAD1E1;

    --color-secondary: #FFB700;
    --color-secondary-light: #FFEDBF;
    --color-secondary-dark: #E5AB14;
    --color-secondary-contrast: #fff;

    --color-black-dark-extra-light: #686781;
    --light-rgba: #080f3408; // TODO: rename

    --color-tertiary: #33455F;
    --color-tertiary-extra-light: #858585;
    --color-tertiary-extra-dark: #212121;
    --color-tertiary-light-dark: #485A73;
    --color-tertiary-light: #8D96A2;
    --color-tertiary-medium: #5A6F87;
    --color-tertiary-dark: #1A2C45;
    --color-tertiary-contrast: #fff;
    --color-tertiary-light-extra: #F1F7FF;

    --color-success: #22C55E;
    --color-success-light: #BBF7D0;
    --color-success-medium-dark: #388041;
    --color-success-dark-light: #36C97F;
    --color-success-dark: #16A34A;
    --color-success-light-white: #F1FFFA;
    --color-success-dark-light-white: #06BA9D;
    --color-success-contrast: #fff;
    --color-success-extra-light: #EDFFEA;

    --color-yellow-green: #c1d732;
    --color-yellow-green-light: #f2fadf;
    --color-yellow: #FFC42D;
    --color-light-green: #E5F4D4;

    --color-warning: #F59E0B;
    --color-warning-light: #FFE6BB;
    --color-warning-extra-light: #FFF8EA;
    --color-warning-dark: #D97706;
    --color-warning-dark-light: #DBA464;
    --color-warning-contrast: #fff;

    --color-danger-primary: #FC615C;
    --color-danger-primary-light: #FFEFEF;
    --color-danger: #EF4444;
    --color-danger-light: #FCA5A5;
    --color-danger-extra-light: #ffd6d6;
    --color-danger-white-light: #FFF0F0;
    --color-danger-light-dark: #FF6565;
    --color-danger-dark: #DC2626;
    --color-danger-contrast: #fff;

    --color-sky: #32d7d7;
    --color-sky-light: #dffafa;

    --color-aqua: #32abd7;
    --color-aqua-light: #dfeffa;

    --color-copper: #B08536;
    --color-copper-purple-light: #FFF9EA;

    --color-purple-extra-dark: #4C3C6A;
    --color-blue-purple: #4232d7;
    --color-purple-medium-dark: #593ba9;
    --color-purple: #954AB9;
    --color-purple-small-light: #9378DE;
    --color-light-extra-pink: #D0C5FF;
    --color-purple-light: #A59DB4;
    --color-purple-extra-light: #B9C9FF;
    --color-purple-dark: #7A6686;
    --color-purple-grey-light: #A59DB4;
    --color-purple-white: #FCFBFF;
    --color-purple-white-light: #FDFDFF;
    --color-purple-white-dark: #F2F0F4;

    --color-white: #FFFFFF;

    --color-green: #95B464;
    --color-green-light-dark: #6B8454;
    --color-green-extra-light: #E2FFF0;
    --color-green-extra-dark-light-white: #E9FDF8;
    --color-green-dark: #2B413A;
    --color-green-dark-light: #005C2D;
    --color-green-light: #55645F;
    --color-green-white-dark: #95A09C;
    --color-green-white: #F9FAF1;
    --color-green-extra-light-white: #FEFFFA;
    --color-green-extra-white: #F0F4F0;

    --color-brown: #CD6767;
    --color-brown-medium-dark: #71513d;
    --color-brown-dark: #63484E;
    --color-brown-dark-light: #946200;
    --color-brown-light-dark: #8E6F6B;
    --color-brown-light: #B1A3A6;
    --color-brown-extra-light: #F4F3F0;
    --color-brown-extra-light-white: #FFFDFD;
    --color-brown-white-light: #FFFCFC;

    --color-wheat-light: #F5F1F2;

    --color-info: #314158;
    --color-info-light: #BAC0C7;
    --color-info-dark: #1A2C45;
    --color-info-contrast: #FFFF;

    --color-black: #242424;
    --color-black-light: #555555;
    --color-black-extra-light: #5A6F87;
    --color-black-dark: #000000;
    --color-black-light-dark: #212121;
    --color-black-contrast: #001141;

    --color-white-slight-blue: #FCFDFF;
    --color-white-light: #FEFEFE;
    --color-white-slight-dark: #F0F2F4;
    --color-white-dark: #F6F6F6;
    --color-white-extra-dark: #F9FBFF;
    --color-white-contrast: #000000;
    --color-white-extra-light: #F7FAFE;
    --color-white-light-slight-dark: #F5F9FF;

    --color-extra-light-grey: #B5C6DC;
    --color-grey-light-dark: #9CAFC6;
    --color-grey-dark: #3D5371;
    --color-gray-deep-light: #596E86;
    --color-extra-grey-dark: #485F7E;
    --color-grey-more-extra-dark: #B2B2B6;
    --color-gray-slight-light: #757575;
    --color-gray-light: #dfedff38;

    --color-white-blue-light: #F1F5FA;
    --color-white-blue-dark: #99A2AF;

    --color-pale-white-blue-light: #F8FAFF;

    --color-blue-extra-light: #EEF6FF;
    --color-blue-white-light: #EDF2F8;
    --color-blue-dark: #101E41;
    --color-blue-alice: #F4F9FF;
    --color-blue-extra-light-blur: #54A0FF14;
    --color-blue-light-blur: #54A0FF26;
    --color-blue-dark-light-blur: #C1DCFF91;

    // TODO: rename
    --color-divider: #DEEDFF;
    --color-stroke: #DFEDFF;
    --color-light-chip-gray: #E7ECF8;
    --color-light-gray: #858585;
    --color-light-white-gray: #E5E9EF;
    --color-orange: #FFC14F;
    --color-light-orange-dark: #D28229;
    --color-light-yellow-dark: #d2b629;
    --color-light-orange: #FA8D50;
    --color-light-orange-light: #FFDDB7;
    --color-light-orange-extra-light: #FFF3EE;
    --color-light-orange-light-dark: #FF834E;
    --color-light-yellow-light: #fff3b7;
    --color-light-yellow-extra-light: #FFF5E2;
    --color-light-yellow-extra-light-white: #FFF5DA;
    --color-light-white: #E5F0FF;
    --color-dialog-header-background: #FBFDFF;
    --color-stroke-light: #E9F2FF;
    --color-chip-background: #F3F7FB;
    --color-chip: #8392A5;
    --color-list-items: #6B788B;
    --color-row-input-border: #DBDBDB;
    --color-sub-heading: #9CAEC6;
    // TODO:rename & used for resume score
    --progress-bar-color: #9E53FE;
    --progress-bar-secondary-color: #E7E8EA;
    --progress-bar-stroke-color: #3101FE;

    // rgba only
    --color-card-box-shadow: rgba(74, 58, 255, .12);
    --color-card-box-shadow-dark: rgba(17, 12, 46, 0.15);
    --color-background-extra-light-blur: rgba(223, 237, 255, 0.22);
    --color-background-light-blur: rgba(255, 255, 255, 0.5);
    --color-background-blur: rgba(255, 255, 255, 0.85);
    --color-background-extra-blur: rgba(255, 255, 255, 0.98);
    --color-stroke-shadow: rgba(223, 237, 255, 0.5);
    --color-selected-mat-row: rgba(243, 244, 246, 0.40);
    --color-table-border: rgba(82, 63, 105, .06);
    --color-blue-light-blur: rgba(82, 63, 105, .06);
    --color-gray-light-rgba: rgba(223, 237, 255, 0.22);
    --color-brown-light-blur: rgba(90, 63, 69, 0.16);

    // transparent
    --black-transparent-2: rgba(0, 0, 0, .2);
    --black-transparent-12: rgba(0, 0, 0, .12);
    --black-transparent-14: rgba(0, 0, 0, .14);

    // gradient
    --card-background-gradient: linear-gradient(41deg, rgba(23, 74, 255, 1) 26%, rgba(0, 228, 253, 1) 68%);
    --seattle-website-theme: linear-gradient(-45deg, #CDEBFC, #EAD1E1, #CDEBFC, #EAD1E1);
    --sydney-website-theme: linear-gradient(-45deg, #6D97FE, #FAF4FF, #D3F6FF);
    --barcelona-website-theme: linear-gradient(-45deg, #9E53FE, #02D6FE, #7CB4FE, #91FFFE);
    --singapore-website-theme: linear-gradient(-45deg, #D8A5FF, #89D8FF, #EDEEFF);
    --sanfrancisco-website-theme: linear-gradient(-45deg, #F16F80, #BA8FBB, #B4C9E7, #E1CADE);
    --chicage-website-theme: linear-gradient(-45deg, #0BE4FF, #3101FE, #119AF8);
    --dark-and-light-blue-gradient: linear-gradient(90deg, rgba(52, 63, 253, 1) 44%, rgba(84, 236, 255, 1) 100%) no-repeat center;

    //box shadow
    --card-box-shadow: 0px 7px 14px 0px rgba(8, 15, 52, 0.03);


    //////////////////NOTE: Do not use
    --color-blue-extra-dark: #33455F;
    --color-pink-extra-dark: #4B3C6A;
    --color-brown-extra-dark: #63484E;
    --color-white-extra-dark-light: #E5E8E2;
    --color-pink-extra-light: #EAE4F5;

    --dark-blue: #1a3066;
    --extra-dark-blue: #1B2F64;
    --dark-light-black: #546681;
    --dark-extra-light-black: #686781;
    --smooth-white: #E4F1FF;
    --color-light-gray-black: #dfedff38;

    --color-blue-purple-light: #eadffa;
    --color-light-purple: #EEEBFF;
    --color-brown-white: #F4F3F0;

    --color-alice-blue: #F4F9FF;
    --color-light-wheat: #F5F1F2;

    --color-extra-light-white: #F7FAFE;

    --color-light-grey: #F0F0F0;
    --color-more-extra-grey-dark: #B2B2B6;
    --color-light-gray-extra: #757575;

    --color-blue-light-blur: #54A0FF26;
    --color-extra-grey-light: #576F8B;
    --color-blue-dark-blur: #54A0FF14;

    --color-grey-extra-light: #576F8B;
    --color-gray-dark-blur: #C1DCFF91;

    // rgba only
    --color-blue-light-blur: rgba(82, 63, 105, .06);
    --sidebar-width: 150px;
}

html, body {
    font-family: 'Poppins', sans-serif !important;
    height: 100%;
}

body {
    padding: 0;
    padding-bottom: env(safe-area-inset-bottom);
    background-image: url('assets/images/talenlio-background-image.avif');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    width: 100%;
    height: 100%;
}

.horizontal-separator {
    border-top: solid 1px var(--color-stroke);
}

.glass-effect {
    background: var(--color-background-light-blur) !important;
    backdrop-filter: blur(6.7px) !important;
    -webkit-backdrop-filter: blur(6.7px);
}

.form-card {
    background: var(--color-white);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-background-blur);
}

.box-shadow {
    box-shadow: var(--color-card-box-shadow-dark) 0 48px 100px 0;
}

.box-shadow-blur {
    box-shadow: 0 7px 14px 0 rgba(8, 15, 52, 0.03);
}

.box-shadow-dark {
    box-shadow: 0 2px 2px 0 var(--black-transparent-14), 0 3px 1px -2px var(--black-transparent-2), 0 1px 5px 0 var(--black-transparent-12);
}

.light-box-shadow {
    box-shadow: var(--card-box-shadow);
}

.custom-chip {
    background: var(--color-blue-alice);
    border: 1px solid var(--color-stroke);
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--color-grey-dark);
}

.white-blur-background-card {
    border-radius: 24px;
    border: 1px solid var(--color-white);
    background: var(--color-background-blur);
    box-shadow: var(--card-box-shadow);
    backdrop-filter: blur(2px);
}

.loading-outer-container {
    display: inline-flex;
    padding: var(--spacing-sm, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 8px);
    border-radius: 26px;
    border-bottom: 1px solid var(--color-stroke);
    background: var(--color-background-blur);
    backdrop-filter: blur(20.5px);
    position: relative;
    width: 100%;
    overflow: hidden;
}

//custom-snack-bar
.snack-bar-container {
    border-radius: 14px !important;
    padding: 4px !important;
    background: var(--color-white);
    color: var(--color-black);
    box-shadow: none !important;
}

// TABLE --------
table {
    width: 100%;
    border: solid 1px var(--color-stroke) !important;
    border-radius: 12px !important;
}

.selected-row {
    background: var(--color-border-gray);
}

.common-menu-item, .common-menu-item:hover {
    color: var(--color-grey-dark);
    background-color: transparent;
}

.danger-menu-item, .danger-menu-item:hover {
    color: var(--color-danger-light-dark);
    background-color: transparent;
}

.custom-snackbar {
    background: unset !important;
    box-shadow: none !important;
    color: unset !important;
    padding: 0 !important;
}

//animation type
.animate-slide-left {
    animation: slideInLeft 0.3s ease-out;
}

.animate-slide-right {
    animation: slideInRight 0.3s ease-out;
}

.animate-slide-up {
    animation: fade-up 0.3s ease-out forwards;
}

.animate-bounce {
    animation: bounce-animation 0.4s ease-out forwards;
}

.animation-fade {
    animation: fade 5s ease-in-out infinite;
}

.animation-fade-in {
    animation: fade-in 1s ease-in forwards;
}

.fade-in {
    animation: fadeIn 300ms;
}

.slide-in-left {
    animation: slideInLeft 0.8s forwards;
}

.slide-in-right {
    animation: slideInRight 0.8s forwards;
}

.hide-to-left {
    animation: hideToLeft 0.8s forwards;
}

.hide-to-right {
    animation: hideToRight 0.8s forwards;
}

.zoom-in {
    animation: zoomIn 0.8s forwards;
}

.zoom-out {
    animation: zoomOut 0.8s forwards;
}

@keyframes hideToLeft {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(-200%);
        opacity: 1;
    }
}

@keyframes hideToRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }
    100% {
        transform: translateX(200%);
        opacity: 1;
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade-down {
    0% {
        opacity: 0;
        transform: translateY(-30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade-left {
    0% {
        opacity: 0;
        transform: translateX(60px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fade-right {
    0% {
        opacity: 0;
        transform: translateX(-60px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.90);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes zoomOut {
    0% {
        transform: scale(1.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
